<div class="yes-no-dialog event-dialog-content" mat-dialog-content>
  <div (click)="close()" class="close-button">
    <i class="fal fa-times-circle"></i>
  </div>
  <div class="dialog-body">
    <div class="dialog-title">{{ title | translate }}</div>
    <div>
      <div class="message">{{ message | translate }}</div>
      @if (user) {
      <div class="user-cont">
        <app-user-avatar
          [user]="user"
          [size]="45"
          [placeholderFontSize]="16"
        ></app-user-avatar>
        <div>{{ user.name }}</div>
      </div>
      }
    </div>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <div class="btn btn-primary-outline" (click)="close()">
      {{ noLabel | translate }}
    </div>
    <div class="btn btn-primary" (click)="yes()">
      {{ yesLabel | translate }}
    </div>
  </div>
</div>
