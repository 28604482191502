import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventTemplateFont } from '@models/design-templates/event-template-font.model';
import { User } from '@models/users/user.model';
import { UserAvatarComponent } from '@modules/shared/components/user-avatar/user-avatar.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-yes-no',
  standalone: true,
  imports: [CommonModule, TranslateModule, UserAvatarComponent],
  templateUrl: './yes-no.dialog.html',
  styleUrl: './yes-no.dialog.scss',
})
export class YesNoDialog {
  title = '';
  message = '';
  yesLabel = 'APP.YES';
  noLabel = 'APP.NO';
  font?: EventTemplateFont;

  isDeleteDialog = false;

  user?: User;

  constructor(
    public dialogRef: MatDialogRef<YesNoDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data) {
      this.title = data.title;
      this.message = data.message;
      this.font = data.font;
      this.user = data.user;
      this.isDeleteDialog = !!data.isDeleteDialog;
      if (this.isDeleteDialog) {
        this.yesLabel = 'APP.YES_DELETE';
      }
      if (data.yesLabel && data.yesLabel !== '') {
        this.yesLabel = data.yesLabel;
      }
      if (data.noLabel && data.noLabel !== '') {
        this.noLabel = data.noLabel;
      }
    }
  }

  close() {
    this.dialogRef.close();
  }

  yes() {
    this.dialogRef.close(true);
  }
}
